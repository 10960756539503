import { Injectable } from '@angular/core';
import { EPermissions } from '@auth/login';
import { Capability } from '@operate/charging-infrastructure-detail/model/capability.model';
import { ChargingStationCapability } from '@operate/charging-infrastructure-detail/model/charging-infrastructure-detail.model';
import { SessionFacade } from '@shared/store/session.facade';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private sessionFacade: SessionFacade) {}

  getCurrentUserId(): number {
    return this.sessionFacade.currentUser$.getValue().id;
  }

  userHasPermission(permission: EPermissions): boolean {
    const userLogin = this.sessionFacade.currentUser$.getValue();
    return userLogin !== null && userLogin.permissions && this.checkAllPermissions(permission);
  }

  userHasInternalRole(): boolean {
    const userLogin = this.sessionFacade.currentUser$.getValue();
    return userLogin.internalRole;
  }

  chargingStationHasCapabilityAndIsPermitted(
    chargingStationCapabilities: Capability[],
    capability: ChargingStationCapability
  ): boolean {
    const capabilities = chargingStationCapabilities?.filter((elem: Capability) => {
      return ChargingStationCapability[capability] === elem.id;
    });

    if (capabilities && capabilities.length > 0) {
      return capabilities[0].capableAndPermitted;
    } else {
      return false;
    }
  }

  private checkAllPermissions(permission: EPermissions): boolean {
    const userLogin = this.sessionFacade.currentUser$.getValue();
    const permissionFound = userLogin.permissions.find(
      (userPermission: EPermissions) => userPermission === permission
    );

    return permissionFound !== undefined;
  }
}
