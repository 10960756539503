<div class="dropdown-container">
  <div
    class="input-container body-m"
    [emobDropdownOverlay]="overlay"
    (openOverlay)="onOpenOverlay($event)"
    (keydown.arrowDown)="openOverlay()"
    (keydown.arrowUp)="openOverlay()"
    [disabled]="disabled"
    [multiSelect]="multiSelect"
    (click)="onTouched()"
    [style.pointer-events]="disabled ? 'none' : 'auto'"
    [ngClass]="{ disabled: disabled }"
  >
    <input
      #input
      class="input body-m"
      autocomplete="off"
      [readonly]="!searchable"
      [formControl]="control"
      [placeholder]="searchPlaceholder"
      [style.cursor]="searchable ? 'auto' : 'pointer'"
    />
    <div class="selected-item-template-container">
      <ng-container *ngIf="selectedItemTemplate && !multiSelect">
        <ng-container
          *ngTemplateOutlet="selectedItemTemplate; context: { selectedItem: controlDir.value }"
        ></ng-container>
      </ng-container>
      <ng-container *ngIf="selectedItemTemplate && multiSelect">
        <ng-container
          *ngTemplateOutlet="selectedItemTemplate; context: { selectedItems: selectedItems }"
        ></ng-container>
      </ng-container>
    </div>
    <eop-icon
      tabindex="-1"
      *ngIf="!isOverlayOpen"
      [identifier]="'small-chevron-down-filled'"
      [color]="disabled ? IconColor.DISABLED : IconColor.NAVIGATIONAL"
      [vaylensFont]="true"
    ></eop-icon>
    <eop-icon
      tabindex="-1"
      *ngIf="isOverlayOpen"
      [identifier]="'small-chevron-up-filled'"
      [color]="disabled ? IconColor.DISABLED : IconColor.NAVIGATIONAL"
      [vaylensFont]="true"
    ></eop-icon>
  </div>

  <span
    *ngIf="errorTemplate && !isOverlayOpen && control.touched && control.invalid && !disabled"
    class="errorText"
  >
    <ng-container
      *ngTemplateOutlet="errorTemplate; context: { error: controlDir.errors | errorKey }"
    ></ng-container>
  </span>

  <emob-dropdown-overlay
    #overlay="emobDropdownOverlay"
    (selectedItemChange)="onSelectedItemChange($event)"
    [activeItemIndex]="activeItemIndex"
  >
    <ng-template emobDropdownOverlayContent>
      <ng-container *ngIf="!loading">
        <ng-container
          *ngIf="searchable ? (items | filter : (control.valueChanges | async)) : items as result"
        >
          <cdk-virtual-scroll-viewport
            #viewport
            itemSize="40"
            class="viewport"
            minBufferPx="200"
            maxBufferPx="400"
          >
            <emob-option *ngIf="singleSelectDeselactable && !multiSelect">{{
              placeholder
            }}</emob-option>
            <emob-option
              [id]="item.value"
              [ngClass]="{
                selected: isSelected(item),
                'selected-background': isSelected(item) && !multiSelect,
                'with-checkbox': multiSelect
              }"
              *cdkVirtualFor="let item of result; let i = index; templateCacheSize: 0"
              [item]="item"
              [index]="i"
            >
              <ng-container *ngIf="itemTemplate">
                <ng-container
                  *ngTemplateOutlet="itemTemplate; context: { itemName: item.name, item: item }"
                ></ng-container>
              </ng-container>
              <div *ngIf="!itemTemplate && !multiSelect">
                {{ item.name }}
              </div>
              <div *ngIf="!itemTemplate && multiSelect" class="multiselect-item">
                <input
                  type="checkbox"
                  id="{{ item.value }}"
                  name="{{ item.name }}"
                  [checked]="isSelected(item)"
                />
                <label for="{{ item.name }}">
                  <span class="item-name"> {{ item.name }} </span>
                </label>
              </div>
            </emob-option>
            <emob-option class="no-result" *ngIf="!result.length">{{ noResultText }}</emob-option>
          </cdk-virtual-scroll-viewport>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="loading">
        <emob-loading-indicator-spinner></emob-loading-indicator-spinner>
      </ng-container>
    </ng-template>
  </emob-dropdown-overlay>
</div>
