import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { isBrowserInGerman } from '@shared/components/header/menu-footer/menu-footer.component';
import { SessionFacade } from '@shared/store/session.facade';
import { EWaitResponseType } from '@shared/store/session.model';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';
import { IconColor } from '@widgets/eop-icon';
import { ProgressStates } from '@widgets/save-button';
import { environment } from 'environments/environment';
import { isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  protected readonly ButtonColor = ButtonColor;
  readonly unsubscribe$ = new Subject();

  username: string;
  password: string;
  progressState: ProgressStates;
  unauthNavigatedPage: string;
  IconColor = IconColor;
  showPassword = false;
  dataProtectionUrl: string;
  legalNoticeUrl: string;

  constructor(
    private sessionFacade: SessionFacade,
    private router: Router,
    private urlSerializer: UrlSerializer
  ) {}

  ngOnInit(): void {
    this.progressState = ProgressStates.Begin;
    this.sessionFacade.unauthNavigatedPage$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.unauthNavigatedPage = data;
    });
    this.sessionFacade.waitLogin$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (data === EWaitResponseType.FINISH || data === EWaitResponseType.FINISH_WITH_ERROR) {
        this.progressState = ProgressStates.Begin;
        this.navigateToSavedPage();
      } else {
        this.progressState = ProgressStates.Sent;
      }
    });
    const useGermanUrls = isBrowserInGerman();
    this.dataProtectionUrl = useGermanUrls
      ? environment.dataProtectionUrlDE
      : environment.dataProtectionUrlEN;
    this.legalNoticeUrl = useGermanUrls
      ? environment.legalNoticeUrlDE
      : environment.legalNoticeUrlEN;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  login(): void {
    this.sessionFacade.startLogin(this.username.trim(), this.password);
  }

  private navigateToSavedPage(): void {
    this.sessionFacade.saveUnauthNavigatedPageToStore('');
    if (isEmpty(this.unauthNavigatedPage)) {
      this.router.navigate(['/monitor/dashboard']);
    } else {
      const urlParsed = this.urlSerializer.parse(this.unauthNavigatedPage);
      const urlFragment = this.unauthNavigatedPage.split('?')[0];
      this.router.navigate([urlFragment], {
        queryParams: urlParsed.queryParams,
      });
    }
  }
}
