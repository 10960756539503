import { ActionReducer, MetaReducer } from '@ngrx/store';
import { hydrationMetaReducer } from './hydration/hydration.reducer';
import { storeReset } from './ngrx-meta-reducer';
import { NgrxMetaActions } from './ngrx-meta.actions';

export function storeResetMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return storeReset({ action: NgrxMetaActions.resetStore })(reducer);
}

export const metaReducers: MetaReducer<any>[] = [storeResetMetaReducer, hydrationMetaReducer];
