import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EopButtonModule } from '@widgets/eop-button/eop-button.module';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { BoxSubHeaderComponent } from './box-subheader/box-subheader.component';
import {
  BoxActionComponent,
  BoxComponent,
  BoxCustomActionComponent,
  BoxFooterComponent,
  BoxHeadlineComponent,
} from './index';

@NgModule({
  imports: [
    CommonModule,
    EopIconModule,
    MatTooltipModule,
    InlineSVGModule.forRoot({ baseUrl: '/' }),
    EopButtonModule,
  ],
  declarations: [
    BoxComponent,
    BoxHeadlineComponent,
    BoxSubHeaderComponent,
    BoxFooterComponent,
    BoxActionComponent,
    BoxCustomActionComponent,
  ],
  exports: [
    BoxComponent,
    BoxActionComponent,
    BoxCustomActionComponent,
    BoxHeadlineComponent,
    BoxSubHeaderComponent,
    BoxFooterComponent,
  ],
})
export class BoxModule {}
