import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ISettings, Language } from '@auth/login';
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
  GoogleAnalyticsLabel,
} from '@shared/google-analytics/google-analytics.model';
import { GoogleAnalyticsService } from '@shared/google-analytics/google-analytics.service';
import { SessionFacade } from '@shared/store/session.facade';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { isBrowserInGerman } from '../menu-footer/menu-footer.component';
import { MENU_POINTS } from '../menu-points.model';

@Component({
  selector: 'eop-header-menu',
  templateUrl: 'header-menu.component.html',
  styleUrls: ['header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  screenWidth: number;
  $screenWidthDesktopMax = 1700;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.handleTooltipsWithMenuCollapse();
  }

  @ViewChildren(MatExpansionPanel)
  expPanels: QueryList<MatExpansionPanel>;
  notification$ = this.sessionFacade.notification$;
  menuPoints = MENU_POINTS;
  legalMenuPoints = { sections: [] };
  isMenuCollapsed = false;
  activeMenuGroup: string;

  @Input()
  get menuCollapsed(): boolean {
    return this.isMenuCollapsed;
  }

  @Output()
  menuCollapsedChange = new EventEmitter<boolean>();

  set menuCollapsed(visible: boolean) {
    this.isMenuCollapsed = visible;
    this.menuCollapsedChange.emit(this.isMenuCollapsed);
  }

  isGermanLanguage = false;
  dataProtectionUrl: string;
  legalNoticeUrl: string;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private cdr: ChangeDetectorRef,
    private sessionFacade: SessionFacade
  ) {}

  ngOnInit() {
    this.setupLegalMenuPoints();
  }

  ngAfterViewInit() {
    this.handleTooltipsWithMenuCollapse();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  collapseMenu() {
    this.googleAnalyticsService.sendEvent(
      GoogleAnalyticsCategory.SIDE_MENU,
      GoogleAnalyticsAction.COLLAPSE,
      !this.isMenuCollapsed ? GoogleAnalyticsLabel.OPEN : GoogleAnalyticsLabel.CLOSE
    );
    this.menuCollapsed = !this.isMenuCollapsed;
    if (this.menuCollapsed) {
      this.sessionFacade.collapseSidebar();
    } else {
      this.sessionFacade.expandSidebar();
    }
    this.cdr.detectChanges();
  }

  private handleTooltipsWithMenuCollapse() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > this.$screenWidthDesktopMax) {
      this.isMenuCollapsed = false;
      this.sessionFacade.expandSidebar();
    } else {
      this.isMenuCollapsed = true;
      this.sessionFacade.collapseSidebar();
    }
    this.cdr.detectChanges();
  }

  protected readonly IconWeight = IconWeight;
  protected readonly IconColor = IconColor;

  onMenuGroupChange(activeGroup: string) {
    this.activeMenuGroup = activeGroup;
  }

  protected readonly event = event;

  private setupLegalMenuPoints() {
    this.getUserLanguage();

    this.legalMenuPoints = {
      sections: [
        {
          name: 'HEADER.LEGAL',
          route: '/legal',
          testId: 'header-menu-legal',
          icon: 'judge-outlined',
          links: [
            {
              route: this.dataProtectionUrl,
              openInBlank: true,
              name: 'PRIVACY_POLICY.TITLE',
              testId: 'header-menu-legal-data-protection',
              icon: 'privacy-policies-outlined',
            },
            {
              route: this.legalNoticeUrl,
              openInBlank: true,
              name: 'IMPRESSUM.TITLE',
              testId: 'header-menu-legal-impressum',
              icon: 'legal-notice-outlined',
            },
          ],
        },
      ],
    };
  }
  private getUserLanguage() {
    this.sessionFacade.userSettings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((settings: ISettings) => {
        this.isGermanLanguage = settings
          ? settings.locale === Language.German
          : isBrowserInGerman();
        this.dataProtectionUrl = this.isGermanLanguage
          ? environment.dataProtectionUrlDE
          : environment.dataProtectionUrlEN;
        this.legalNoticeUrl = this.isGermanLanguage
          ? environment.legalNoticeUrlDE
          : environment.legalNoticeUrlEN;
      });
  }
}
