import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { SessionActions } from './session.actions';
import {
  EWaitResponseType,
  ISessionData,
  LoginData,
  SessionState,
  UserFilterOptions,
} from './session.model';

// Initial states
const INITIAL_LOGIN_STATE: LoginData = {
  userLogin: null,
  settingValueList: {},
};

const INITIAL_SESSION_STATE: ISessionData = {
  notification: null,
  messagesShown: false,
  sidebarCollapsed: null,
  waitLogin: EWaitResponseType.FINISH,
  unauthNavigatedPage: null,
  hasNewMessages: false,
};

const INITIAL_USER_FILTER_OPTIONS_STATE: UserFilterOptions = {
  businessPartnerId: null,
  businessPartnerLocationId: null,
  businessPartnerEmaidGroupId: null,
};

// Login Reducer
export const loginReducer = createReducer(
  INITIAL_LOGIN_STATE,
  on(SessionActions.loginSuccess, (state, { userLogin }) => ({
    ...state,
    userLogin: userLogin,
  })),
  on(SessionActions.setUserSettings, (state, { settings }) => ({
    ...state,
    userLogin: { ...state.userLogin, settings: settings },
  })),
  on(SessionActions.setValueList, (state, { settings }) => ({
    ...state,
    settingValueList: settings,
  }))
);

// Session Reducer
export const sessionReducer = createReducer(
  INITIAL_SESSION_STATE,
  on(SessionActions.showTopNotification, (state, { notification }) => ({
    ...state,
    notification: notification,
  })),
  on(SessionActions.closeTopNotification, state => ({
    ...state,
    notification: null,
  })),
  on(SessionActions.openMessages, state => ({
    ...state,
    messagesShown: true,
    hasNewMessages: true,
  })),
  on(SessionActions.closeMessages, state => ({
    ...state,
    messagesShown: false,
    hasNewMessages: false,
  })),
  on(SessionActions.toggleMessages, state => ({
    ...state,
    messagesShown: !state.messagesShown,
    hasNewMessages: false,
  })),
  on(SessionActions.collapseSidebar, state => ({
    ...state,
    sidebarCollapsed: true,
  })),
  on(SessionActions.expandSidebar, state => ({
    ...state,
    sidebarCollapsed: false,
  })),
  on(SessionActions.waitLogin, (state, { value }) => ({
    ...state,
    waitLogin: value,
  })),
  on(SessionActions.unauthNavigatedPage, (state, { value }) => ({
    ...state,
    unauthNavigatedPage: value,
  }))
);

// User Filter Options Reducer
export const userFilterOptionsReducer = createReducer(
  INITIAL_USER_FILTER_OPTIONS_STATE,
  on(SessionActions.saveUserFilterOptions, (state, { options }) => ({
    ...state,
    ...options,
  })),
  on(SessionActions.resetUserFilterOptions, () => ({}))
);

export const reducers: ActionReducerMap<SessionState> = {
  login: loginReducer,
  sessionData: sessionReducer,
  userFilterOptions: userFilterOptionsReducer,
};
