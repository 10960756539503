<div class="input-wrapper">
  <input
    type="number"
    class="pricing-set-time"
    [class]="{ error: error }"
    placeholder="HH"
    min="0"
    max="23"
    maxlength="2"
    [value]="hour"
    (input)="onHourChange($event)"
    (blur)="onHourBlur($event)"
    [disabled]="disabled"
    data-test="hours"
  />
  <span class="pricing-set-time-separator">:</span>
  <input
    #minuteInput
    type="number"
    class="pricing-set-time"
    [class]="{ error: error }"
    placeholder="MM"
    min="0"
    max="59"
    maxlength="2"
    [value]="minute"
    (input)="onMinuteChange($event)"
    (blur)="onMinuteBlur($event)"
    [disabled]="disabled"
    data-test="minutes"
  />
  <div class="am-pm-dropdown-wrapper" [formGroup]="amPmForm">
    <emob-dropdown
      *ngIf="userTimeFormat === 'hh:mm a'"
      class="am-pm-dropdown"
      [singleSelectDeselactable]="false"
      [items]="amPmDropdownItems"
      formControlName="amPm"
      data-test="amPm"
    ></emob-dropdown>
  </div>
</div>
