import { formatCurrency, formatNumber, getCurrencySymbol } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Language } from '@auth/login';
import { UserSettingsService } from '@shared/services/util';
import { LanguageService } from '@shared/translate/language.service';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'userDecimal',
})
export class UserDecimalPipe implements PipeTransform {
  constructor(
    private userSettingsService: UserSettingsService,
    private languageService: LanguageService
  ) {}

  transform(
    value: number,
    digitsInfo: string = '1.2-2',
    currency?: string,
    display: 'code' | 'wide' | 'narrow' = 'code'
  ): string {
    const decimalSeparator = this.userSettingsService.getDecimalSeparator();
    const thousandsSeparator = decimalSeparator === ',' ? '.' : ',';
    let locale = this.languageService.getUserLanguageWithFallback();
    if (locale === Language.Empty) {
      locale = Language.English;
    }

    if (!/\d\.\d-\d/.test(digitsInfo)) {
      throw new Error('The assigned digitsInfo does not match the pattern d.d-d');
    }
    const splitDigitsInfo = digitsInfo.split(/[.-]/);
    const minDecimalDigits = Number(splitDigitsInfo[1]);
    const maxDecimalDigits = Number(splitDigitsInfo[2]);

    let formattedValue = this.getFormattedValue(currency, display, value, locale, digitsInfo);

    const separators = formattedValue.match(/[.,]/g);
    if (separators) {
      const firstSeparator = separators[0];
      if (separators.length > 1) {
        formattedValue = this.setMultipleSeparators(
          firstSeparator,
          thousandsSeparator,
          decimalSeparator,
          formattedValue
        );
      } else {
        formattedValue = this.setSeparators(
          value,
          minDecimalDigits,
          maxDecimalDigits,
          firstSeparator,
          thousandsSeparator,
          decimalSeparator,
          formattedValue
        );
      }
    }

    return formattedValue;
  }

  private getFormattedValue(
    currency: string,
    display: 'code' | 'wide' | 'narrow' = 'code',
    value: number,
    locale: Language,
    digitsInfo: string
  ) {
    if (currency) {
      const currencySymbol = display === 'code' ? currency : getCurrencySymbol(currency, display);
      return formatCurrency(value, locale, currencySymbol, currency, digitsInfo);
    } else {
      return formatNumber(value, locale, digitsInfo);
    }
  }

  private setMultipleSeparators(
    firstSeparator: string,
    thousandsSeparator: ',' | '.',
    decimalSeparator: string,
    formattedValue: string
  ): string {
    // More than one? We got a thousands separator
    if (firstSeparator !== thousandsSeparator) {
      formattedValue = formattedValue.replace(/[.,]/g, separator => {
        // Replace thousands separator with decimal separator and vice versa
        return separator === thousandsSeparator ? decimalSeparator : thousandsSeparator;
      });
    }
    return formattedValue;
  }

  private setSeparators(
    value: number,
    minDecimalDigits: number,
    maxDecimalDigits: number,
    firstSeparator: string,
    thousandsSeparator: ',' | '.',
    decimalSeparator: string,
    formattedValue: string
  ): string {
    // Only one? We got the decimal separator ... or did we?
    if ((value % 1 === 0 && minDecimalDigits === 0) || maxDecimalDigits === 0) {
      // Yikes! We are dealing with an integer here, the separator is the thousands separator
      if (firstSeparator !== thousandsSeparator) {
        formattedValue = formattedValue.replace(firstSeparator, thousandsSeparator);
      }
    } else {
      if (firstSeparator !== decimalSeparator) {
        formattedValue = formattedValue.replace(firstSeparator, decimalSeparator);
      }
    }

    return formattedValue;
  }
}
