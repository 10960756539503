<mat-toolbar>
  <button
    eopButton
    *ngIf="!inProgress; else spinner"
    [matMenuTriggerFor]="menu"
    (click)="toggleDropdown()"
    [rightIcon]="dropdownIsOpen ? 'small-chevron-up-filled' : 'small-chevron-down-filled'"
    [disabled]="disabled"
    [attr.data-test]="dataTestId"
  >
    {{ selectedOption || placeholder }}
    <div class="vertical-line"></div>
  </button>
</mat-toolbar>

<mat-menu
  #menu="matMenu"
  (closed)="toggleDropdown()"
  yPosition="above"
  class="mat-menu-panel-buttons"
>
  <button
    mat-menu-item
    *ngFor="let option of options; let i = index"
    (click)="onOptionSelect(option)"
    [attr.data-test]="dataTestId + '-' + i"
  >
    {{ option.label }}
  </button>
</mat-menu>
<ng-template #spinner>
  <spinner [spinnerSize]="42"></spinner>
</ng-template>
