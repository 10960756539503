import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationLevel } from '@shared/services/notification';
import { IconSize } from '@widgets/eop-icon';
import { SnackbarService } from '@widgets/snackbar/snackbar.service';

export interface FileData {
  name: string;
  mime: string;
  data: string;
  hasErrors: boolean;
  size?: number;
}

@Component({
  selector: 'eop-multiple-file-upload',
  templateUrl: './multiple-file-upload.component.html',
  styleUrls: ['./multiple-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleFileUploadComponent),
      multi: true,
    },
  ],
})
export class MultipleFileUploadComponent implements ControlValueAccessor {
  @Input() width: number;
  @Input() height: number;
  @Input() fileType: string;
  @Input() acceptedMine: string = null;
  @Input() mineErrorMessage: string;
  @Input() disabled: boolean;
  @Input() maxFileSize: number = null;
  @Input() infoMessage: string;
  @Output() uploadStarted: EventEmitter<void> = new EventEmitter<void>();
  @Output() uploadFinished: EventEmitter<void> = new EventEmitter<void>();
  loading = false;
  allFilesData: FileData[] = [];
  protected readonly IconSize = IconSize;
  private objectValue: any;

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  registerOnChange(fn: (value: FileData[]) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    this.objectValue = obj;
  }

  fileInputClick(event: HTMLInputElement) {
    event.value = null;
  }

  onSelectFiles(files: FileList): Promise<boolean>[] {
    const promises: Promise<boolean>[] = [];
    for (let i = 0; i < files.length; i++) {
      this.loading = true;
      this.cdr.detectChanges();
      this.uploadStarted.emit();
      const file = files[i];
      const promise = new Promise(resolve => {
        if (file) {
          const fileData: FileData = {
            name: null,
            mime: null,
            data: null,
            hasErrors: false,
            size: 0,
          };
          fileData.size = file.size;
          fileData.name = file.name;
          const validSize = this.isFileSizeValid(fileData);
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (eventReader: ProgressEvent) => {
            const fileSplit = ((eventReader.target as FileReader).result as string).split(',');
            fileData.data = fileSplit[1];
            fileData.mime = fileSplit[0].split(';')[0].split(':')[1];
            const validType = this.isFileTypeValid(fileData);
            fileData.hasErrors = !(validSize && validType);
            const fileWithSameName = this.allFilesData.find(f => f.name === fileData.name);
            if (!fileData.hasErrors && !fileWithSameName) {
              this.allFilesData.push(fileData);
            }
            this.onChangeCallback(this.allFilesData);
            this.loading = false;
            this.cdr.detectChanges();
            this.uploadFinished.emit();
            resolve(true);
          };
        }
      });
      promises.push(promise as Promise<boolean>);
    }
    return promises;
  }

  removeFile(file: FileData) {
    this.allFilesData = this.allFilesData.filter(f => f.name !== file.name);
    this.onChangeCallback(this.allFilesData);
  }

  isValidImageFormat(file: File): boolean {
    // List of valid MIME types for images
    const validImageTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/svg+xml',
      'image/bmp',
      'image/x-icon',
      'image/vnd.microsoft.icon',
      'image/webp',
      'image/tiff',
      'image/heic',
    ];
    return validImageTypes.includes(file['mime']);
  }

  private onChangeCallback: (value: FileData[]) => void = () => {};

  private onTouchedCallback: () => void = () => {};

  private isFileSizeValid(fileData: FileData): boolean {
    if (this.maxFileSize !== null && fileData.size > this.maxFileSize * 1000000) {
      this.snackbar.openSnackbar(
        this.translate.instant('GLOBAL.MAX_FILE_SIZE_IS') + ` ${this.maxFileSize} MB`,
        NotificationLevel.ERROR,
        8000
      );
      this.loading = false;
      this.cdr.detectChanges();
      return false;
    }
    return true;
  }

  private isFileTypeValid(fileData: FileData): boolean {
    if (this.acceptedMine !== null && this.acceptedMine !== fileData.mime) {
      this.snackbar.openSnackbar(this.mineErrorMessage, NotificationLevel.ERROR, 8000);
      this.loading = false;
      this.cdr.detectChanges();
      return false;
    }
    return true;
  }
}
