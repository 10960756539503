import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[dragAndDropUpload]',
})
export class DragAndDropUploadDirective {
  @Output()
  onFileDropped = new EventEmitter<any>();
  @Input()
  allowMultiple = false;

  @HostBinding('style.background-color') private background = '#fff';
  @HostBinding('style.opacity') private opacity = '1';

  //Dragover listener
  @HostListener('dragover', ['$event'])
  onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#9ecbec';
    this.opacity = '0.8';
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event'])
  onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';
    this.opacity = '1';
  }

  //Drop listener
  @HostListener('drop', ['$event'])
  onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';
    this.opacity = '1';
    if (this.allowMultiple) {
      this.onFileDropped.emit(evt.dataTransfer.files);
    } else {
      this.onFileDropped.emit(evt.dataTransfer.files[0]);
    }
  }
}
