import { Injectable } from '@angular/core';
import { IUserLogin } from '@auth/login';
import { SessionFacade } from '@shared/store/session.facade';
import { UserFilterOptions } from '@shared/store/session.model';
import { UserFilterOptionsFacade } from '@shared/store/user-filter-options.facade';
import { take } from 'rxjs/operators';

enum MessageKeys {
  LOGGED_IN = 'EOP_LOGGED_IN',
  LOGGED_OUT = 'EOP_LOGGED_OUT',
  GET_USER_FILTER_OPTIONS = 'GET_USER_FILTER_OPTIONS',
  USER_FILTER_OPTIONS = 'USER_FILTER_OPTIONS',
}

@Injectable({
  providedIn: 'root',
})
export class StorageBroadcastService {
  constructor(
    private sessionFacade: SessionFacade,
    private userFilterOptionsFacade: UserFilterOptionsFacade
  ) {}

  public start() {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  /**
   * Broadcast the login event to all other open tabs of the browser
   * to prevent multiple user logins in the browser
   */
  public broadcastLogin(userLogin: IUserLogin) {
    localStorage.setItem(MessageKeys.LOGGED_IN, JSON.stringify(userLogin));
    localStorage.removeItem(MessageKeys.LOGGED_IN);
  }

  /**
   * Broadcast the logout event to all other open tabs of the browser
   * to prevent multiple user logins in the browser
   */
  public broadcastLogout() {
    localStorage.setItem(MessageKeys.LOGGED_OUT, MessageKeys.LOGGED_OUT);
    localStorage.removeItem(MessageKeys.LOGGED_OUT);
  }

  public requestForUserFilterOptions() {
    localStorage.setItem(MessageKeys.GET_USER_FILTER_OPTIONS, MessageKeys.GET_USER_FILTER_OPTIONS);
    localStorage.removeItem(MessageKeys.GET_USER_FILTER_OPTIONS);
  }

  public updateUserFilterOptionsOnOtherTabs(options: UserFilterOptions) {
    localStorage.setItem(MessageKeys.USER_FILTER_OPTIONS, JSON.stringify(options));
    localStorage.removeItem(MessageKeys.USER_FILTER_OPTIONS);
  }

  private storageEventListener(event: StorageEvent) {
    switch (event.key) {
      case MessageKeys.LOGGED_IN:
        if (!event.newValue) break; // removeItem is also throwing an event
        const userLogin: IUserLogin = JSON.parse(event.newValue);
        if (!userLogin) break;
        this.sessionFacade.loginSuccess(userLogin);
        break;
      case MessageKeys.LOGGED_OUT:
        if (!event.newValue) break; // removeItem is also throwing an event
        this.sessionFacade.logout();
        break;
      case MessageKeys.GET_USER_FILTER_OPTIONS: {
        if (!event.newValue) break;
        this.userFilterOptionsFacade.userFilterOptions$.pipe(take(1)).subscribe(options => {
          if (Object.keys(options).length !== 0) {
            localStorage.setItem(MessageKeys.USER_FILTER_OPTIONS, JSON.stringify(options));
            localStorage.removeItem(MessageKeys.USER_FILTER_OPTIONS);
          }
        });
        break;
      }
      case MessageKeys.USER_FILTER_OPTIONS: {
        if (!event.newValue) break;
        this.userFilterOptionsFacade.userFilterOptions$.pipe(take(1)).subscribe(options => {
          if (Object.keys(options).length !== 0) {
            const receivedOptions: UserFilterOptions = JSON.parse(event.newValue);
            this.userFilterOptionsFacade.saveUserFilterOptions(receivedOptions);
          }
        });
        break;
      }
    }
  }
}
