import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoginMessagesEntry } from '@portal/login-messages/data/login-message.model';
import { SessionFacade } from '@shared/store/session.facade';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { Observable, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { LoginMessagesPollerService } from './services/login-messages-poller.service';

@Component({
  selector: 'eop-show-notification',
  templateUrl: './show-notification.component.html',
  styleUrls: ['./show-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowNotificationComponent implements OnInit {
  private readonly pollTimeInMinutes = 10;
  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;
  readonly IconSize = IconSize;

  userLanguage$ = this.sessionFacade.userLanguage$;
  hasNewMessages$ = this.sessionFacade.hasNewMessages$;
  activeMessages$: Observable<LoginMessagesEntry[]>;
  activeMessageIds: number[];

  constructor(
    private loginMessagesPoller: LoginMessagesPollerService,
    private sessionFacade: SessionFacade,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activeMessages$ = timer(0, this.pollTimeInMinutes * 60 * 1000).pipe(
      switchMap(() => this.loginMessagesPoller.getActiveLoginMessages()),
      tap(messages => this.handleMessageChanges(messages)),
      tap(() => this.cdr.detectChanges())
    );
  }

  toggleMessages(): void {
    this.sessionFacade.toggleMessages();
    this.markNotificationsSeen();
  }

  markNotificationsSeen(): void {
    this.loginMessagesPoller.markNotificationsSeen(this.activeMessageIds);
  }

  private handleMessageChanges(activeMessages: LoginMessagesEntry[]): void {
    if (activeMessages.length === 0) {
      return;
    }

    const hasNewNotifications = this.loginMessagesPoller.hasNewNotifications(activeMessages);

    if (hasNewNotifications) {
      this.sessionFacade.openMessages();
    } else {
      this.sessionFacade.closeMessages();
    }
    this.activeMessageIds = activeMessages.map(message => message.id);
  }
}
