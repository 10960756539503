import { ICsvData } from '@shared/sheets/models/sheets.model';

export enum EAuthenticationSource {
  REMOTE = 'REMOTE',
  OPERATION = 'OPERATION',
  MOBILE = 'MOBILE',
  PAYONE = 'PAYONE',
  HUBJECT_CPO = 'HUBJECT_CPO',
  HUBJECT_EMP = 'HUBJECT_EMP',
  OCPI_CPO = 'OCPI_CPO',
  OCPI_EMP = 'OCPI_EMP',
  RFID = 'RFID',
  PNC = 'PNC',
  PILOT_SIGNAL = 'PILOT_SIGNAL',
  LAN = 'LAN',
  NO_AUTH = 'NO_AUTH',
  UNDEFINED = 'UNDEFINED',
  PAYMENT_TERMINAL = 'PAYMENT_TERMINAL',
  PAYMENT_TERMINAL_STANDALONE = 'PAYMENT_TERMINAL_STANDALONE',
}

export interface BillingRecordsList {
  billingRecords: BillingRecord[];
  paging: Paging;
}

export interface BillingRecordsCsv extends ICsvData {
  paging: Paging;
}

export interface BillingRecord {
  chargePortId: string;
  chargingStationId: string;
  chargingStationUuid: string;
  bdrId: string;
  chargingStart: string;
  chargingEnd: string;
  energyConsumption: number;
  energyUnit: string;
  revenueCusKwh: number;
  currencyCus: string;
  chargingDuration: string;
  authSource: EAuthenticationSource;
  status: BdrRatingStatus;
  reasons: BdrReason[];
  pricingReasons: BdrReason[];
  customerReasons?: BdrReason[];
  ekaStatus: EkaStatus;
  emaid: string;
  couponId: string;
  location: Location;
  emaidReference: string;
  emaidDescription: string;
  calibrationLawMethod: string;
}

export interface EkaFileCellData {
  ekaStatus: EkaStatus;
  calibrationLawMethod: string;
  bdrId: string;
  searchParams: EkaFileDownloadParameter;
}

export interface EkaFileDownloadParameter {
  dateFrom: string;
  dateTo: string;
  partnerId: string;
  locationId: string;
  view: 'emp' | 'cpo';
}

export enum BdrRatingStatus {
  ERROR = 'ERROR',
  EXCLUDED = 'EXCLUDED',
  OK = 'OK',
  UNKNOWN = 'UNKNOWN',
  OK_WITH_PRICING_REASONS = 'OK_WITH_PRICING_REASONS',
}

export enum BdrReason {
  // ERROR
  DOUBLE_BDR = 'DOUBLE_BDR',
  EMP_UNKNOWN = 'EMP_UNKNOWN',
  SESSION_START_IN_FUTURE = 'SESSION_START_IN_FUTURE',
  NEGATIVE_POWER = 'NEGATIVE_POWER',
  NEGATIVE_CONSUMPTION = 'NEGATIVE_CONSUMPTION',
  NEGATIVE_DURATION = 'NEGATIVE_DURATION',
  POWER_IMPLAUSIBLE_FOR_EVSE = 'POWER_IMPLAUSIBLE_FOR_EVSE',
  // EXCLUDED
  CONSUMPTION_TOO_LOW = 'CONSUMPTION_TOO_LOW',
  CONSUMPTION_TOO_HIGH = 'CONSUMPTION_TOO_HIGH',
  DURATION_TOO_SHORT = 'DURATION_TOO_SHORT',
  DURATION_TOO_LONG = 'DURATION_TOO_LONG',
  FAIR_MODE = 'FAIR_MODE',
  CROSS_COUNTRY = 'CROSS_COUNTRY',
  CPO_VOUCHER = 'CPO_VOUCHER',
  MANUAL = 'MANUAL',
  GOODWILL = 'GOODWILL',
  // OK
  OWNCHARGE = 'OWNCHARGE',
  NO_CALIBRATION_LAW_COMPLIANCE = 'NO_CALIBRATION_LAW_COMPLIANCE',
}

type EnumDictionary<T extends string, U> = {
  [K in T]: U;
};

export const BdrReasonDictionary: EnumDictionary<BdrReason, BdrRatingStatus> = {
  [BdrReason.DOUBLE_BDR]: BdrRatingStatus.ERROR,
  [BdrReason.EMP_UNKNOWN]: BdrRatingStatus.ERROR,
  [BdrReason.SESSION_START_IN_FUTURE]: BdrRatingStatus.ERROR,
  [BdrReason.NEGATIVE_POWER]: BdrRatingStatus.ERROR,
  [BdrReason.NEGATIVE_CONSUMPTION]: BdrRatingStatus.ERROR,
  [BdrReason.NEGATIVE_DURATION]: BdrRatingStatus.ERROR,
  [BdrReason.POWER_IMPLAUSIBLE_FOR_EVSE]: BdrRatingStatus.ERROR,
  [BdrReason.CONSUMPTION_TOO_LOW]: BdrRatingStatus.EXCLUDED,
  [BdrReason.CONSUMPTION_TOO_HIGH]: BdrRatingStatus.EXCLUDED,
  [BdrReason.DURATION_TOO_SHORT]: BdrRatingStatus.EXCLUDED,
  [BdrReason.DURATION_TOO_LONG]: BdrRatingStatus.EXCLUDED,
  [BdrReason.FAIR_MODE]: BdrRatingStatus.EXCLUDED,
  [BdrReason.CROSS_COUNTRY]: BdrRatingStatus.EXCLUDED,
  [BdrReason.CPO_VOUCHER]: BdrRatingStatus.EXCLUDED,
  [BdrReason.MANUAL]: BdrRatingStatus.EXCLUDED,
  [BdrReason.GOODWILL]: BdrRatingStatus.EXCLUDED,
  [BdrReason.OWNCHARGE]: BdrRatingStatus.OK,
  [BdrReason.NO_CALIBRATION_LAW_COMPLIANCE]: BdrRatingStatus.OK,
};

export enum EkaStatus {
  IRRELEVANT = 'IRRELEVANT',
  OPEN = 'OPEN',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export interface Location {
  country: string;
  city: string;
  zipCode: string;
  street: string;
}

export interface Paging {
  currentPage: number;
  itemsPerPage: number;
  nextPageAvailable: boolean;
}

export interface Partner {
  id: string;
  name: string;
  locations: PartnerLocation[];
}

export interface PartnerLocation {
  uuid: string;
  name: string;
  defaultLocation: boolean;
}

export interface PartnerDropdownPayload {
  id: string;
  partner: Partner;
  partnerLocation: PartnerLocation[];
  withDefaultLocation: boolean;
}

export interface SearchParameters {
  businessPartnerId?: string;
  locationId?: string;
  emaidGroupId?: string;
  dateFrom: string;
  dateTo: string;
  sortOrder?: string;
  sortColumn?: string;
  page?: number;
  itemsPerPage?: number;
  country?: string;
  city?: string;
  zipcode?: string;
  streetSearchList?: string[];
  ekaStatusList?: string[];
  currentType?: string;
  authenticationTypePredicate?: string[];
  ratingStatus?: string;
  reasonList?: string[];
  currencyList?: string;
  search?: string;
}

export interface SearchParametersBackend {
  'business-partner-id'?: string;
  'location-id'?: string;
  'emaid-group-id'?: string;
  'date-from': string;
  'date-to': string;
  'sort-order'?: string;
  'sort-column'?: string;
  page?: number;
  'items-per-page'?: number;
  country?: string;
  city?: string;
  zipcode?: string;
  'street-search-list'?: string[];
  'eka-status-list'?: string[];
  'current-type'?: string;
  'authentication-type-predicate'?: string[];
  'rating-status'?: string;
  'reason-list'?: string[];
  'currency-list'?: string;
  search?: string;
}

export interface EnergyAmount {
  timeUnit: TimeUnit;
  powerUnit: string;
  records: EnergyAmountRecord[];
}

export enum TimeUnit {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

export interface EnergyAmountRecord {
  powerValue: number;
  revenueValue: number;
  periodEndTimestamp: string;
  periodStartTimestamp: string;
}

export interface ChargeDuration {
  timeUnit: TimeUnit;
  durations: ChargeDurationRecord[];
}

export interface ChargeDurationRecord {
  chargeDuration: string;
  periodEndTimestamp: string;
  periodStartTimestamp: string;
}

export interface AuthenticationSourcesPercentage {
  [key: string]: NumberAndPercentage;
}

export interface NumberAndPercentage {
  number: number;
  percentage: number;
}

export enum ChargeRecordFilterTypes {
  Contracts,
  ChargingInfrastructure,
}
