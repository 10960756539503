import { AfterViewInit, Component, inject, Input, ViewChild } from '@angular/core';
import { SessionFacade } from '@shared/store/session.facade';
import { IconColor, IconWeight } from '@widgets/eop-icon';
import { HeaderMenuComponent } from '../header-menu/header-menu.component';
import { QuickAccessComponent } from '../quick-access/quick-access/quick-access.component';

@Component({
  selector: 'eop-global-header',
  templateUrl: 'global-header.component.html',
  styleUrls: ['global-header.component.scss'],
})
export class GlobalHeaderComponent implements AfterViewInit {
  @ViewChild('searchBar')
  searchBar: QuickAccessComponent;

  @ViewChild(HeaderMenuComponent)
  menu: HeaderMenuComponent;

  @Input()
  isFullWidth = false;

  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;

  private sessionFacade: SessionFacade = inject(SessionFacade);

  ngAfterViewInit(): void {
    this.searchBar?.popoverVisible$?.subscribe((opened: boolean) => {
      if (opened) {
        this.sessionFacade.closeMessages();
      }
    });
  }

  collapseMenu(): void {
    this.menu.collapseMenu();
  }
}
