import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProgressStates } from '@widgets/save-button';

export interface ButtonOption {
  label: string;
  value: string;
}

@Component({
  selector: 'eop-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownButtonComponent {
  protected readonly ProgressStates = ProgressStates;
  @Input()
  disabled: boolean;
  @Input()
  placeholder: string;
  @Input()
  options: ButtonOption[];
  @Input()
  inProgress: boolean;
  @Input()
  dataTestId: string = 'dropdown-button';
  @Output()
  optionSelected: EventEmitter<string> = new EventEmitter();

  dropdownIsOpen: boolean;
  selectedOption: string;

  onOptionSelect(option: ButtonOption) {
    this.selectedOption = option.label;
    this.inProgress = true;
    this.optionSelected.emit(option.value);
  }

  toggleDropdown() {
    this.dropdownIsOpen = !this.dropdownIsOpen;
  }
}
