import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState } from './session.model';

export const sessionState = createFeatureSelector<SessionState>('session');

export const userLogin = createSelector(sessionState, state => state.login.userLogin);
export const userSettings = createSelector(sessionState, state => state.login.userLogin?.settings);
export const userLanguage = createSelector(
  sessionState,
  state => state.login.userLogin?.settings?.locale
);
export const userTimezone = createSelector(
  sessionState,
  state => state.login.userLogin?.settings?.timezone
);
export const datePattern = createSelector(
  sessionState,
  state => state.login.userLogin?.settings?.datePattern
);
export const timePattern = createSelector(
  sessionState,
  state => state.login.userLogin?.settings?.timePattern
);
export const hasNewMessages = createSelector(
  sessionState,
  state => state.sessionData.hasNewMessages
);
export const waitLogin = createSelector(sessionState, state => state.sessionData.waitLogin);
export const settingValueList = createSelector(sessionState, state => state.login.settingValueList);
export const unauthNavigatedPage = createSelector(
  sessionState,
  state => state.sessionData.unauthNavigatedPage
);
export const userFilterOptions = createSelector(sessionState, state => state.userFilterOptions);
export const selectedPartner = createSelector(
  sessionState,
  state => state.userFilterOptions.businessPartnerId
);
export const notification = createSelector(sessionState, state => state.sessionData.notification);
export const messagesShown = createSelector(sessionState, state => state.sessionData.messagesShown);
export const sidebarCollapsed = createSelector(
  sessionState,
  state => state.sessionData.sidebarCollapsed
);
