import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Language } from '@auth/login';
import { LoginMessagesEntry } from '@portal/login-messages/data/login-message.model';
import { SessionFacade } from '@shared/store/session.facade';

@Component({
  selector: 'eop-login-notification',
  templateUrl: './login-notification.component.html',
  styleUrls: ['./login-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginNotificationComponent {
  @Input()
  activeMessages: LoginMessagesEntry[];

  @Input()
  userLanguage: Language;

  @Output()
  markNotificationsSeen = new EventEmitter<void>();

  messagesShown$ = this.sessionFacade.messagesShown$;

  constructor(private sessionFacade: SessionFacade) {}

  closeMessages(): void {
    this.markNotificationsSeen.emit();
    this.sessionFacade.closeMessages();
  }
}
