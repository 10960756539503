import { StationSource } from '@shared/services/util/station-source-icon.service';

export enum ChargingStationAccessibility {
  FREE_ACCESS = 'FREE_ACCESS',
  PAID_ACCESS = 'PAID_ACCESS',
  RESTRICTED = 'RESTRICTED',
  TEST_STATION = 'TEST_STATION',
  UNDEFINED = 'UNDEFINED',
}

export interface IdNameField {
  id: string;
  name: string;
}

export enum ChargingStationState {
  PLANNED = 'PLANNED',
  IBET = 'IBET',
  ABET = 'ABET',
}

export enum ChargingStationType {
  LSG = 'LSG',
  LSX = 'LSX',
  OCPP = 'OCPP',
  ROAMING = 'ROAMING',
}

export enum ChargingStationProtocol {
  LG2WAN = 'LG2WAN',
  OCPP_15 = 'OCPP_15',
  OCPP_16_VPN = 'OCPP_16_VPN',
  OCPP_16_INTERNET = 'OCPP_16_INTERNET',
  OCPP_20 = 'OCPP_20',
  UNDEFINED = 'UNDEFINED',
}

export enum ChargingStationCommunicationDeviceType {
  GATEWAY = 'GATEWAY',
  MODEM = 'MODEM',
}

export interface ChargingStationCommunicationDevice {
  entityVersion: number;
  id: string;
  name: string;
  type: ChargingStationCommunicationDeviceType;
  ipAddress: string;
  imei: string;
  simCardNumber: string;
  iccId: string;
  passwordExists: boolean;
  passwordModified: string;
  smlServerId: string;
  controllerId: string;
}

export interface ChargingStationPasswordStatus {
  lastAttempt: string;
  connectionSuccess: boolean;
  connectionFailedReason: ConnectionFailedReason;
  acceptAnyPasswordValidUntil: string;
  passwordModified: string;
}

export enum ConnectionFailedReason {
  NO_PASSWORD_PROVIDED = 'NO_PASSWORD_PROVIDED',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  OK = 'OK',
}

export enum ChargingStationConnectorType {
  CTYPE1 = 'CTYPE1',
  CTYPE2 = 'CTYPE2',
  TYPE2 = 'TYPE2',
  TYPE3 = 'TYPE3',
  BS1363 = 'BS1363',
  CEE_7_7 = 'CEE_7_7',
  CCS1 = 'CCS1',
  CCS2 = 'CCS2',
  G105 = 'G105',
  CEE_63A = 'CEE_63A',
  CEE_125A = 'CEE_125A',
  POWERLOCK_400A = 'POWERLOCK_400A',
  POWERLOCK_630A = 'POWERLOCK_630A',
  GB_T_AC = 'GB_T_AC',
  GB_T_DC = 'GB_T_DC',
}

export enum ChargingStationConnectorFormat {
  CABLE = 'CABLE',
  SOCKET = 'SOCKET',
}

export interface ChargingStationConnector {
  type: ChargingStationConnectorType;
  index: number;
  format: ChargingStationConnectorFormat;
}

export enum ChargingStationCurrentType {
  DC = 'DC',
  AC = 'AC',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum ChargingStationCalibrationLawMethod {
  ZKS = 'ZKS',
  NONE = 'NONE',
  OCMF = 'OCMF',
  EXTERNAL = 'EXTERNAL',
}

export enum ChargingStationMeterPosition {
  LEFT = 'LEFT',
  MIDDLE = 'MIDDLE',
  RIGHT = 'RIGHT',
}

export interface ChargingStationMeter {
  calibratedUntil: string;
  id: string;
  number: string;
  publicKey: string;
  serverId: string;
}

export interface ChargingStationLocation {
  city: string;
  countryIso3: string;
  installationAddressAdditionEn: string;
  installationAddressAdditionLocal: string;
  latitude: number;
  longitude: number;
  street: string;
  timeZone: string;
  zipCode: string;
  locationType: string;
}

export interface ChargingStationEvse {
  entityVersion: number;
  nfcRfidAuth: boolean;
  noAuth: boolean;
  pncAuth: boolean;
  remoteAuth: boolean;
  iso15118Auth: boolean;
  connectors: ChargingStationConnector[];
  currentType: ChargingStationCurrentType;
  maxChargingVoltage: number;
  maxChargingCurrent: number;
  maxChargingPower: number;
  phases: number;
  evseId: string;
  communicationDevice: ChargingStationCommunicationDevice;
  calibrationLawMethod: ChargingStationCalibrationLawMethod;
  id: string;
  meter: ChargingStationMeter;
  meteringPointId: string;
  name: string;
  ocppLogicalIndex: number;
  position: ChargingStationMeterPosition;
  smlServerId: string;
  meterAutoDetection: boolean;
}

export interface ChargingStationAccessibilityDetails {
  accessibility: ChargingStationAccessibility;
  poi: boolean;
  roamingName: string;
  directPayment: boolean;
  userCharging: boolean;
  externalRoaming: boolean;
  hubjectPreRequirementsFulfilled: boolean;
  ocpiPreRequirementsFulfilled: boolean;
  authTypePreRequirementsFulfilled: boolean;
}

export interface MobileNetworkDetails {
  apnName: string;
  connectionStatus: ConnectionStatus;
  modified: string;
  lastSession: {
    timestamp: string;
    provider: string;
    country: string;
    radioTechnology: string;
  };
  simCardStatus: string;
}

export enum ConnectionStatus {
  CONNECTED_NOW = 'CONNECTED_NOW',
  CONNECTED_IN_LAST_24H = 'CONNECTED_IN_LAST_24H',
  NOT_CONNECTED_IN_LAST_24H = 'NOT_CONNECTED_IN_LAST_24H',
  UNKNOWN = 'UNKNOWN',
}

export interface MobileNetworkRefreshResponse {
  imsis: string[];
  iccids: string[];
}

export enum SimIdType {
  ICCID = 'ICCID',
  IMSI = 'IMSI',
}

export interface ChargingStation {
  entityVersion: number;
  accessibility: ChargingStationAccessibilityDetails;
  cpo: IdNameField;
  state: ChargingStationState;
  type: ChargingStationType;
  source: StationSource;
  emergencyContact: string;
  evses: ChargingStationEvse[];
  greenEnergy: boolean;
  hotlineNr: string;
  id: string;
  installDate: string;
  modified: string; // date time in UTC, ISO 8601
  location: ChargingStationLocation;
  manufacturer: string;
  modelInfo: string;
  model: string;
  vendor: string;
  firmware: string;
  communicationDevice: ChargingStationCommunicationDevice;
  ocppPort: string;
  ocppBasePath: string;
  ocppChargeboxId: string;
  serialNumber: string;
  virtualStation: boolean;
  customerReference: string;
  protocol: ChargingStationProtocol;
  llmMaster: boolean;
  deviceConfigProcessed: boolean;
  deviceConfigModified: string;
  deviceConfigProcessedFields: DeviceConfigurationFields[];
  paymentTerminal: PaymentTerminalKind;
}

export interface DeviceConfigurationProcessedFields {
  phases: boolean;
  maxVoltage: boolean;
  maxCurrent: boolean;
  authTypes: boolean;
  authRequired: boolean;
}

export enum DeviceConfigurationFields {
  PHASES = 'PHASES',
  MAX_VOLTAGE = 'MAX_VOLTAGE',
  MAX_CURRENT = 'MAX_CURRENT',
  AUTH_TYPES = 'AUTH_TYPES',
  AUTH_REQUIRED = 'AUTH_REQUIRED',
  ICCID = 'ICCID',
  SIM_CARD_NUMBER = 'SIM_CARD_NUMBER',
  IP_ADDRESS = 'IP_ADDRESS',
  IMEI = 'IMEI',
}

export enum PaymentTerminalKind {
  INTEGRATED = 'INTEGRATED',
  STANDALONE = 'STANDALONE',
  UNKNOWN = 'UNKNOWN',
}

export interface Provider {
  name: string;
  id: string;
}

export interface Providers {
  chargeportOperators: Provider[];
}

export interface Country {
  code: string;
  iso: string;
  name: string;
}
