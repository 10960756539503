import { IAccountSettingValueLists, ISettings, IUserLogin } from '@auth/login';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EWaitResponseType, INotification, UserFilterOptions } from './session.model';

export const SessionActions = createActionGroup({
  source: 'Session',
  events: {
    'Start Login': props<{ username: string; password: string }>(),
    'Wait Login': props<{ value: EWaitResponseType }>(),
    Login: props<{ username: string; password: string }>(),
    'Login Success': props<{ userLogin: IUserLogin }>(),
    Logout: emptyProps(),
    'Logout Success': emptyProps(),
    'Logout Error': emptyProps(),
    'Set Value List': props<{ settings: IAccountSettingValueLists }>(),
    'Set User Settings': props<{ settings: ISettings }>(),
    'Show Top Notification': props<{ notification: INotification }>(),
    'Close Top Notification': emptyProps(),
    'Collapse Sidebar': emptyProps(),
    'Expand Sidebar': emptyProps(),
    'Hydration Completed': emptyProps(),
    'Unauth Navigated Page': props<{ value: string }>(),
    'Open Messages': emptyProps(),
    'Close Messages': emptyProps(),
    'Toggle Messages': emptyProps(),
    'Save User Filter Options': props<{ options: UserFilterOptions }>(),
    'Reset User Filter Options': emptyProps(),
  },
});
