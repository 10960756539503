import { IAccountSettingValueLists, IUserLogin } from '@auth/login';
import { NotificationLevel } from '@shared/services/notification';

export interface SessionState {
  login?: LoginData;
  sessionData?: ISessionData;
  userFilterOptions?: UserFilterOptions;
}

export interface LoginData {
  userLogin?: IUserLogin;
  settingValueList?: IAccountSettingValueLists;
}

export interface INotification {
  notificationLevel?: NotificationLevel;
  message?: string;
  traceId?: string;
  reloadButton?: boolean;
}

export enum EWaitResponseType {
  FINISH = 'FINISH',
  FINISH_WITH_ERROR = 'FINISH_WITH_ERROR',
  WAITING = 'WAITING',
}

export interface ISessionData {
  notification?: INotification;
  messagesShown: boolean;
  sidebarCollapsed?: boolean;
  waitLogin?: EWaitResponseType;
  unauthNavigatedPage?: string;
  hasNewMessages?: boolean;
}

export interface UserFilterOptions {
  businessPartnerId?: string;
  businessPartnerLocationId?: string;
  businessPartnerEmaidGroupId?: string;
}

export interface TypeWithId {
  id?: string | number;
}

export interface TypeWithPartner {
  partner: TypeWithId;
}

export interface TypeWithUuid {
  uuid: string;
}

export interface TypeWithBusinessPartnerId {
  businessPartnerId: string;
}
