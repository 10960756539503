import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { IAppState } from '@shared/store/models';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import * as HydrationActions from './hydration.actions';

@Injectable()
export class HydrationEffects implements OnInitEffects {
  hydrate$ = createEffect(() =>
    this.action$.pipe(
      ofType(HydrationActions.hydrate),
      map(() => {
        const storageValue = localStorage.getItem('state');
        if (storageValue) {
          try {
            const state: IAppState = JSON.parse(storageValue);
            return HydrationActions.hydrateSuccess({
              // only rehydrate this part of the state
              state: {
                locations: state.locations,
                session: {
                  login: state.session?.login,
                  userFilterOptions: state.session?.userFilterOptions,
                },
              },
            });
          } catch {
            localStorage.removeItem('state');
          }
        }
        return HydrationActions.hydrateFailure();
      })
    )
  );

  serialize$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(HydrationActions.hydrateSuccess, HydrationActions.hydrateFailure),
        switchMap(() => this.store),
        distinctUntilChanged(),
        tap(state => localStorage.setItem('state', JSON.stringify(state)))
      ),
    { dispatch: false }
  );

  constructor(private action$: Actions, private store: Store<IAppState>) {}

  ngrxOnInitEffects(): Action {
    return HydrationActions.hydrate();
  }
}
