<ul>
  <li
    *ngFor="let navigationItem of navigationItems"
    class="tabs"
    [matTooltip]="navigationItem.title"
    [matTooltipShowDelay]="300"
  >
    <div
      class="tab-button"
      (click)="onClick(navigationItem)"
      [ngClass]="{
        active: navigationItem === currentItem,
        'tab--disabled': navigationItem.disabled
      }"
    >
      <eop-icon
        class="icon"
        *ngIf="navigationItem.icon"
        [identifier]="navigationItem.icon"
        [weight]="IconWeight.BOLD"
        [clickable]="!navigationItem.disabled"
        [color]="navigationItem.disabled ? IconColor.DISABLED : IconColor.INHERIT"
        [size]="IconSize.SMALL"
      ></eop-icon>

      <div class="tab-title">
        {{ navigationItem.title }}
      </div>
    </div>
  </li>
</ul>
