import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { EPermissions, IUserLogin } from '@auth/login';
import { PermissionService } from '@shared/services/util';
import { SessionFacade } from '@shared/store/session.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'eop-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  @Input() menuCollapsed = false;
  @Input() activeGroupName: string;
  @Output() activeGroupChange: EventEmitter<string> = new EventEmitter<string>();

  readonly GROUP_NAME = 'USER-GROUP';

  sessionFacade: SessionFacade = inject(SessionFacade);
  permissionService: PermissionService = inject(PermissionService);

  userLogin$ = this.sessionFacade.userLogin$ as Observable<IUserLogin>;

  logout(): void {
    this.sessionFacade.logout();
  }

  hasPermissionForUserProfileManage(): boolean {
    return this.permissionService.userHasPermission(
      EPermissions.MANAGE_USER_PROFIL_PERSONAL_SETTINGS
    );
  }

  get expanded(): boolean {
    return this.GROUP_NAME === this.activeGroupName;
  }

  setOpenedPanel(): void {
    if (this.activeGroupName !== this.GROUP_NAME) {
      this.activeGroupChange.emit(this.GROUP_NAME);
    }
  }
}
