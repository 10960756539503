import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UserSettingsService } from '@shared/services/util';
import { DropdownItem } from '@widgets/dropdown/dropdown-type';
import { SessionActions } from './session.actions';
import {
  TypeWithBusinessPartnerId,
  TypeWithId,
  TypeWithPartner,
  TypeWithUuid,
  UserFilterOptions,
} from './session.model';
import { selectedPartner, userFilterOptions } from './session.selector';

@Injectable({
  providedIn: 'root',
})
export class UserFilterOptionsFacade {
  readonly selectedPartnerId$ = this.ngrxStore.pipe(select(selectedPartner));
  readonly userFilterOptions$ = this.ngrxStore.pipe(select(userFilterOptions));

  constructor(private userSettingsService: UserSettingsService, private ngrxStore: Store) {}

  saveUserFilterOptions(options: UserFilterOptions) {
    this.ngrxStore.dispatch(
      SessionActions.saveUserFilterOptions({
        options: options,
      })
    );
  }

  saveBusinessPartner<T extends TypeWithId | TypeWithPartner | TypeWithBusinessPartnerId>(
    item: DropdownItem<T>
  ) {
    if (!this.userSettingsService.hasFrontendSessionStoreEnabled()) return;
    let businessPartnerId: string;
    const businessPartnerLocationId = undefined; // leave it undefined to clear location id always if partner changed
    const businessPartnerEmaidGroupId = undefined; // leave it undefined to clear emaid group id always if partner changed
    const filterOptions = this.getFilterOptions();

    if (item) {
      const data = item.value;
      businessPartnerId = this.getBusinessPartnerId(data);
    }
    if (filterOptions?.businessPartnerId !== businessPartnerId) {
      this.saveUserFilterOptions({
        businessPartnerId,
        businessPartnerLocationId,
        businessPartnerEmaidGroupId,
      });
    }
  }

  saveBusinessPartnerLocation<T extends TypeWithUuid | TypeWithId>(item: DropdownItem<T>) {
    if (!this.userSettingsService.hasFrontendSessionStoreEnabled()) return;
    let businessPartnerLocationId: string;
    const filterOptions = this.getFilterOptions();

    if (item) {
      const data = item.value;
      businessPartnerLocationId = this.getBusinessPartnerLocationId(data);
    }
    if (filterOptions.businessPartnerLocationId !== businessPartnerLocationId) {
      this.saveUserFilterOptions({
        businessPartnerLocationId,
      });
    }
  }

  saveBusinessPartnerEmaidGroup<T extends TypeWithId>(item: DropdownItem<T>) {
    if (!this.userSettingsService.hasFrontendSessionStoreEnabled()) return;
    let businessPartnerEmaidGroupId: string;
    const filterOptions = this.getFilterOptions();

    if (item) {
      businessPartnerEmaidGroupId = item.value.id + '';
    }
    if (filterOptions.businessPartnerEmaidGroupId !== businessPartnerEmaidGroupId) {
      this.saveUserFilterOptions({
        businessPartnerEmaidGroupId,
      });
    }
  }

  selectBusinessPartner<T extends TypeWithId | TypeWithPartner | TypeWithBusinessPartnerId>(
    handler: (s: DropdownItem<T>) => void,
    items: DropdownItem<T>[],
    defaultSelectFirst?: boolean
  ) {
    const filterOptions = this.getFilterOptions();
    if (filterOptions.businessPartnerId) {
      const selectedItem = items.find(
        (item: DropdownItem<T>) =>
          this.getBusinessPartnerId(item.value) === filterOptions.businessPartnerId
      );
      if (selectedItem) {
        return handler(selectedItem);
      }
    }
    if (
      items.length === 1 ||
      (!this.userSettingsService.hasFrontendSessionStoreEnabled() && defaultSelectFirst)
    ) {
      handler(items[0]);
    }
  }

  selectBusinessPartnerLocation<T extends TypeWithUuid | TypeWithId>(
    handler: (s: DropdownItem<T>) => void,
    items: DropdownItem<T>[]
  ) {
    const filterOptions = this.getFilterOptions();
    if (filterOptions.businessPartnerLocationId) {
      const selectedItem = items.find(
        (item: DropdownItem<T>) =>
          this.getBusinessPartnerLocationId(item.value) === filterOptions.businessPartnerLocationId
      );
      if (selectedItem) {
        return handler(selectedItem);
      }
    }
    if (items.length > 0) {
      handler(items[0]);
    }
  }

  selectBusinessPartnerEmaidGroup<T extends TypeWithId>(
    handler: (s: DropdownItem<T>) => void,
    items: DropdownItem<T>[],
    defaultSelectFirst?: boolean
  ) {
    const filterOptions = this.getFilterOptions();
    if (filterOptions.businessPartnerEmaidGroupId) {
      const selectedItem = items.find(
        (item: DropdownItem<T>) => item.value.id === filterOptions.businessPartnerEmaidGroupId
      );
      if (selectedItem) {
        return handler(selectedItem);
      }
    }
    if (items.length > 0 && defaultSelectFirst) {
      handler(items[0]);
    }
  }

  private getBusinessPartnerId<T extends TypeWithId | TypeWithPartner | TypeWithBusinessPartnerId>(
    item: T
  ): string {
    const typeWithPartner = item as TypeWithPartner;
    const typeWithId = item as TypeWithId;
    const typeWithBusinessPartnerId = item as TypeWithBusinessPartnerId;

    if (typeWithBusinessPartnerId.businessPartnerId) {
      return typeWithBusinessPartnerId.businessPartnerId;
    } else if (typeWithPartner.partner) {
      return typeWithPartner.partner.id + '';
    } else {
      return typeWithId.id + '';
    }
  }

  private getBusinessPartnerLocationId<T extends TypeWithUuid | TypeWithId>(item: T): string {
    const typeWithUuid = item as TypeWithUuid;
    const typeWithId = item as TypeWithId;

    if (typeWithUuid.uuid) {
      return typeWithUuid.uuid;
    } else {
      return typeWithId.id + '';
    }
  }

  private getFilterOptions(): UserFilterOptions {
    let filterOptions;
    this.ngrxStore
      .select(userFilterOptions)
      .subscribe(options => (filterOptions = options))
      .unsubscribe();
    return filterOptions;
  }
}
