import { Injectable } from '@angular/core';
import { IAccountSettingValueLists, IUserLogin } from '@auth/login';
import { SessionFacade } from '@shared/store/session.facade';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  constructor(private sessionFacade: SessionFacade) {}

  getDecimalSeparator(): string {
    let userLogin = this.getUserLogin();
    if (!userLogin) {
      return;
    }
    const decimalSeparatorKey = userLogin.settings.decimalSeparator;
    let settingValueList: IAccountSettingValueLists;
    this.sessionFacade.settingValueList$
      .pipe(take(1))
      .subscribe(setting => (settingValueList = setting));

    const decimalSeparatorValue = settingValueList.numberFormats.find(elem => {
      return elem.key === decimalSeparatorKey;
    });

    return decimalSeparatorValue ? decimalSeparatorValue.value : '.';
  }

  hasFrontendSessionStoreEnabled(): boolean {
    return this.getUserLogin().settings.storeFrontendSession;
  }

  getAddress2(supplementLocal: string, supplementEN: string): { text: string; tooltip: string } {
    let tooltip = '';
    if (supplementLocal && supplementLocal !== '') {
      tooltip =
        supplementEN && supplementEN !== ''
          ? supplementLocal + ' | ' + supplementEN
          : supplementLocal;
    } else if (supplementEN && supplementEN !== '') {
      tooltip = supplementEN;
    }

    return {
      text:
        supplementLocal && supplementLocal !== ''
          ? supplementLocal
          : supplementEN && supplementEN !== ''
          ? supplementEN
          : '',
      tooltip,
    };
  }

  getUserLogin(): IUserLogin {
    return this.sessionFacade.currentUser$.getValue();
  }
}
