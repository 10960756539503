import { ProductScopeEnum } from '@shared/components/charging-station-assignments-tables/charging-station-assignments-tables.component';
import { ChargingSessionDetailedStatus, ChargingSessionStatus } from '@shared/services/util';
import { EWaitResponseType } from '@shared/store/session.model';
import { Capability } from './capability.model';
import {
  ChargingStation,
  ChargingStationCurrentType,
  ChargingStationPasswordStatus,
  DeviceConfigurationFields,
  MobileNetworkDetails,
} from './charging-station.model';
import { CommunicationLogs } from './communication-logs.model';
import { ExternalRoamingPreRequirements } from './external-roaming-pre-requirements.model';

export interface ChargingStationState {
  loading: {
    information: boolean;
    networkDetails: boolean;
    externalRoamingPreRequirements: boolean;
    passwordStatus: boolean;
  };
  chargingStationRoaming: RoamingStatus;
  chargingStationAllowed: boolean;
  chargingStationNotFound: boolean;
  refreshedChargePortConfigs: ChargePortConfig[];
  chargingStationInformation: ChargingStation;
  networkDetails: MobileNetworkDetails;
  chargingStationCapabilities: Capability[];
  chargingStationLogFiles: MultipleChargingStationLogFilesEntity;
  chargingStationCommunicationLogs: CommunicationLogs;
  chargingStationFirmware: string;
  externalRoamingPreRequirements: ExternalRoamingPreRequirements;
  ocppConfiguration: IOcppConfiguration;
  ocppConfigurationNotFound: boolean;
  ocppConfigurationBusy: EWaitResponseType;
  ocppConfigurationUpdateStatus: OcppConfigurationUpdateResultCode;
  loadingAssignments: boolean;
  productAssignments: ProductAssignment[];
  groupAssignments: GroupAssignment[];
  locationAssignments: StationsWithLocations;
  configLoading: boolean;
  requestedToPutIntoOperation: boolean;
  localTime: LocalTimeEntity;
  passwordStatus: ChargingStationPasswordStatus;
  emaidGroupCount: number;
}

export interface MultipleChargingStationLogFilesEntity {
  [chargingStationUuid: string]: {
    [chargePortId: string]: ChargingStationLogFilesEntity;
  };
}

export interface ChargingStationLogFilesEntity {
  logFiles: ChargingStationLogFile[];
  fetchingNewLogFile: boolean;
  fetchingNewLogFileSuccess: boolean;
  fetchingNewLogFileTimestamp: string;
  fetchingNewLogFileOnBackground: boolean;
}

export interface ProductAssignment {
  name: string;
  productType: string;
  productScope?: ProductScopeEnum; // will be set in the frontend
  startDate: string;
}

export interface GroupAssignment {
  groupId: string;
  groupName: string;
  type: GroupType;
  businessPartnerId: string;
  scope: string[];
  members: number;
  userOwned: boolean;
  groupDetailsAllowed: boolean;
}

export interface LocationAssignment {
  id: number;
  uuid: string;
  name: string;
  description: string;
  contact: string;
  hotline: string;
  chargingStationCount: number;
}

export interface StationsWithLocations {
  cpoId?: string;
  stationId: string;
  groupDetailsAllowed: boolean;
  locations: LocationAssignment[];
}

export interface LocalTimeEntity {
  [id: string]: LocalTime;
}

export interface LocalTime {
  localTime?: string;
  chargePointId: string;
  loading: boolean;
}

export enum GroupType {
  OPENING_HOUR_GROUP = 'OPENING_HOUR_GROUP',
  NOTIFICATION_GROUP = 'NOTIFICATION_GROUP',
  CHARGEPORT_GROUP = 'CHARGEPORT_GROUP',
  LOAD_MANAGEMENT_GROUP = 'LOAD_MANAGEMENT_GROUP',
  PAYMENT_TERMINAL_GROUP = 'PAYMENT_TERMINAL_GROUP',
}

export enum ChargingStationCapability {
  ACTIVATE_SERVICE_CONTRACT = 'ACTIVATE_SERVICE_CONTRACT',
  ACTIVATE_SERVICE_COUPON = 'ACTIVATE_SERVICE_COUPON',
  ACTIVATE_SERVICE_GOODWILL = 'ACTIVATE_SERVICE_GOODWILL',
  MANAGE_AUTO_METER_DETECTION = 'MANAGE_AUTO_METER_DETECTION',
  MANAGE_CHARGINGSTATION_MASTERDATA = 'MANAGE_CHARGINGSTATION_MASTERDATA',
  MANAGE_CPO_EXTERNAL_ROAMING = 'MANAGE_CPO_EXTERNAL_ROAMING',
  MANAGE_CPO_ROAMING = 'MANAGE_CPO_ROAMING',
  MANAGE_CP_NOTE = 'MANAGE_CP_NOTE',
  MANAGE_CP_OPERATIVE_STATUS = 'MANAGE_CP_OPERATIVE_STATUS',
  MANAGE_CP_RESTART_FORCE = 'MANAGE_CP_RESTART_FORCE',
  MANAGE_CP_RESTART_REQUEST = 'MANAGE_CP_RESTART_REQUEST',
  MANAGE_CP_STOP_CHARGING = 'MANAGE_CP_STOP_CHARGING',
  MANAGE_CP_TROUBLESHOOTING = 'MANAGE_CP_TROUBLESHOOTING',
  MANAGE_CP_UNLOCK_CONNECTOR = 'MANAGE_CP_UNLOCK_CONNECTOR',
  MANAGE_CREATE_CHARGINGSTATION = 'MANAGE_CREATE_CHARGINGSTATION',
  MANAGE_CS_IBET_FLOW = 'MANAGE_CS_IBET_FLOW',
  MANAGE_CS_PROFESSIONAL = 'MANAGE_CS_PROFESSIONAL',
  MANAGE_DIRECT_PAYMENT_FLAG = 'MANAGE_DIRECT_PAYMENT_FLAG',
  MANAGE_SINGLE_FIRMWARE = 'MANAGE_SINGLE_FIRMWARE',
  MANAGE_INSTALLATION_DATE = 'MANAGE_INSTALLATION_DATE',
  MANAGE_LG2WAN_CONFIGURATION = 'MANAGE_LG2WAN_CONFIGURATION',
  MANAGE_PLATFORM_HOTLINE_NUMBER = 'MANAGE_PLATFORM_HOTLINE_NUMBER',
  MANAGE_SUBSETTINGS_QUICKCONFIG = 'MANAGE_SUBSETTINGS_QUICKCONFIG',
  MANAGE_STATION_PROVIDER = 'MANAGE_STATION_PROVIDER',
  MANAGE_TOUCH_STATION = 'MANAGE_TOUCH_STATION',
  MANAGE_VIRTUAL_STATION = 'MANAGE_VIRTUAL_STATION',
  MANAGE_OCPP_CONFIGURATION = 'MANAGE_OCPP_CONFIGURATION',
  MANAGE_UPDATE_CP_STATUS = 'MANAGE_UPDATE_CP_STATUS',
  MANAGE_USERCHARGING_FLAG = 'MANAGE_USERCHARGING_FLAG',
  MANAGE_LOCAL_LOADMANAGEMENT = 'MANAGE_LOCAL_LOADMANAGEMENT',
  MANAGE_CS_EVENT = 'MANAGE_CS_EVENT',
  UNINSTALL_CHARGINGSTATION = 'UNINSTALL_CHARGINGSTATION',
  VIEW_ACTIVATIONOVERVIEW = 'VIEW_ACTIVATIONOVERVIEW',
  VIEW_CHARGINGDATA_CPO = 'VIEW_CHARGINGDATA_CPO',
  VIEW_CP_NOTE = 'VIEW_CP_NOTE',
  VIEW_CP_TECHNICAL_LOGS_LSG = 'VIEW_CP_TECHNICAL_LOGS_LSG',
  VIEW_CP_TECHNICAL_LOGS_OCPP = 'VIEW_CP_TECHNICAL_LOGS_OCPP',
  VIEW_STATION_SERVICE_LINK = 'VIEW_STATION_SERVICE_LINK',
  VIEW_SINGLE_FIRMWARE = 'VIEW_SINGLE_FIRMWARE',
  VIEW_LG2WAN_CONFIGURATION = 'VIEW_LG2WAN_CONFIGURATION',
  VIEW_OCPP_COMMUNICATION_LOGS = 'VIEW_OCPP_COMMUNICATION_LOGS',
  VIEW_SUBSETTINGS_QUICKCONFIG = 'VIEW_SUBSETTINGS_QUICKCONFIG',
  VIEW_OCPP_CONFIGURATION = 'VIEW_OCPP_CONFIGURATION',
  VIEW_PRODUCTS = 'VIEW_PRODUCTS',
  VIEW_LOCAL_LOADMANAGEMENT = 'VIEW_LOCAL_LOADMANAGEMENT',
  VIEW_CS_EVENT = 'VIEW_CS_EVENT',
  DELETE_CHARGINGSTATION = 'DELETE_CHARGINGSTATION',
}

export enum OcppConfigurationUpdateResultCode {
  CHANGE_ACCEPTED = 'CHANGE_ACCEPTED',
  REBOOT_REQUIRED = 'REBOOT_REQUIRED',
  CHANGE_REJECTED = 'CHANGE_REJECTED',
  CHANGE_IGNORED = 'CHANGE_IGNORED',
}

export enum RoamingStatus {
  UNKNOWN = 'unknown',
  INCONSISTENT = 'inconsistent',
  TRUE = 'true',
  FALSE = 'false',
}

export interface StationFirmwareAssignment {
  isStationPartOfRollout: boolean;
  isStationPartOfNotStartedRollout: boolean;
  isStationInFinalState: boolean;
  rolloutName: string;
}

export interface SessionStatusCounts {
  statusCounts: SessionStatusCount[];
}

export interface SessionStatusCount {
  status: SessionCountStatus;
  count: number;
}

export enum SessionCountStatus {
  SUCCESS = 'SUCCESS',
  PERISHED = 'PERISHED',
  ABORTED = 'ABORTED',
  MINMAX = 'MINMAX',
  SDR_INVALID = 'SDR_INVALID',
  SESSION_START_IN_FUTURE = 'SESSION_START_IN_FUTURE',
  NEGATIVE_DURATION = 'NEGATIVE_DURATION',
  NEGATIVE_CONSUMPTION = 'NEGATIVE_CONSUMPTION',
  POWER_IMPLAUSIBLE_FOR_EVSE = 'POWER_IMPLAUSIBLE_FOR_EVSE',
  NO_CALIBRATION_LAW_COMPLIANCE = 'NO_CALIBRATION_LAW_COMPLIANCE',
  NEGATIVE_POWER = 'NEGATIVE_POWER',
  OTHER = 'OTHER',
}

export interface IEffectiveServiceConstraint {
  value: string;
  unit: string;
  sid: string;
}

export interface AuthenticationAndPaymentOptions {
  nfcRfidClassic: boolean;
  nfcRfidDesFire: boolean;
  remoteAuthentication: boolean;
  plugAndCharge: boolean;
  multitenantDirectPayment: boolean;
  noAuthentication: boolean;
}

export interface AuthenticationAndPaymentOptionsFromPoi {
  nfcRfidAuth: boolean;
  remoteAuth: boolean;
  pncAuth: boolean;
  noAuth: boolean;
  iso15118Auth: boolean;
}

export interface ChargePortNote {
  message: string;
  modificationDate: string;
}

export interface AllChargePortStatus {
  chargePortIdToStatus: { [chargePortUuid: string]: IChargePointStatus };
}

export interface IChargePointStatus {
  status?: string;
  detailedStatus?: string;
  health?: string;
  pwmStatus?: string;
  lastCommunication?: string;
  connectorLocked?: boolean;
  cableConnected?: boolean;
  contactorClosed?: boolean;
  unavailableSince?: string;
  errorCode?: string;
  sessionId?: string;
}

export interface IOcppConfiguration {
  parameters: OcppParameter[];
  totalNumberOfParameters: number;
  paging: Paging;
}

export interface OcppParameter {
  key: string;
  value: string;
  readOnly: boolean;
}

export interface Paging {
  currentPage: number;
  totalPages: number;
  itemsPerPage: number;
  totalItems: number;
}

export interface ChargingSessionInformation {
  chargingSessionId?: string;
  chargedEnergy?: ChargingSessionMeterReading;
  bdrId?: string;
  ended?: string;
  started?: string;
  contractId?: string;
  evseInfo?: {
    evseId: string;
  };
  externalSessionInfo?: ExternalSessionInfo;
  errorMessage?: string;
  meterInfo?: ChargingSessionMeterInfo;
  serviceSessionStatus?: ChargingSessionStatus;
  authenticationInfo?: ChargingSessionAuthenticationInfo;
  serviceSessionDetailStatus?: ChargingSessionDetailedStatus;
}

export interface ExternalSessionInfo {
  externalContractProvider?: string;
  externalCpoId?: string;
  externalEmspId?: string;
  externalSessionId?: string;
  locationUi?: string;
}

export interface ChargingSessionAuthenticationInfo {
  authenticationToken?: string;
  authenticationIdType?: ChargingSessionAuthenticationIdType;
  authenticationSource?: ChargingSessionAuthenticationSource;
}

export interface ChargingSessionMeterInfo {
  currentMeterReading: ChargingSessionMeterReading;
  initialMeterReading: ChargingSessionMeterReading;
}

export interface ChargingSessionMeterReading {
  meterReadingValue: number;
  meterReadingInstant?: string;
  meterReadingUnit: ChargingSessionEnergyUnit;
}

export interface ChargePortConfig extends AuthenticationAndPaymentOptionsFromPoi {
  currentType: ChargingStationCurrentType;
  maxChargingCurrent: number;
  phases: number;
  maxChargingPower: number;
  maxChargingVoltage: number;
  evseId: string;
  deviceConfigProcessed: boolean;
  deviceConfigModified: string;
  deviceConfigProcessedFields: DeviceConfigurationFields[];
}

export enum ChargingSessionEnergyUnit {
  WH = 'WH',
  kWH = 'kWH',
  UNDEFINED = 'UNDEFINED',
}

export enum ChargingSessionAuthenticationIdType {
  RFID = 'RFID',
  VOUCHER = 'VOUCHER',
  UNDEFINED = 'UNDEFINED',
  CONTRACT_ID = 'CONTRACT_ID',
  CREDIT_DEBIT_CARD = 'CREDIT_DEBIT_CARD',
}

export enum ChargingSessionAuthenticationSource {
  OPERATION = 'OPERATION',
  WEB = 'WEB',
  MOBILE = 'MOBILE',
  SMART_VEHICLE = 'SMART_VEHICLE',
  RFID = 'RFID',
  SMS = 'SMS',
  HUBJECT_CPO = 'HUBJECT_CPO',
  HUBJECT_EMP = 'HUBJECT_EMP',
  RESERVATION = 'RESERVATION',
  PAYONE = 'PAYONE',
  OCPI_CPO = 'OCPI_CPO',
  OCPI_EMP = 'OCPI_EMP',
  PNC = 'PNC',
  NO_AUTH = 'NO_AUTH',
  PILOT_SIGNAL = 'PILOT_SIGNAL',
  COUPON = 'COUPON',
  LAN = 'LAN',
  PREPAID = 'PREPAID',
  PAYMENT_TERMINAL = 'PAYMENT_TERMINAL',
  PAYMENT_TERMINAL_STANDALONE = 'PAYMENT_TERMINAL_STANDALONE',
  UNDEFINED = 'UNDEFINED',
}

export interface ChargingStationLogFile {
  fileName: string;
  lastModified: string;
}

export enum EChargingStationActiveState {
  ACTIVATED = 'OPERATIVE',
  DEACTIVATED = 'INOPERATIVE',
}

export interface IRoamingStatus {
  platformRoaming: boolean;
  userCharging: boolean;
  emaidGroupsCount: number;
}
