import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ISettings, Language } from '@auth/login';
import { AuthenticationService } from '@shared/services/auth';
import { SessionFacade } from '@shared/store/session.facade';
import { environment } from 'environments/environment';
import { switchMap, take } from 'rxjs/operators';

export const isBrowserInGerman = (): boolean => {
  let lang = navigator.language || window.navigator.language;
  lang = lang.split('-')[0].toLowerCase();
  return lang === Language.German;
};

@Component({
  selector: 'eop-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuFooterComponent implements OnInit {
  showTranslationToggle = false;
  connectivityTesterUrl: string = environment.connectivityTesterUrl;

  @Input() menuCollapsed: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private sessionFacade: SessionFacade
  ) {}

  ngOnInit() {
    this.showTranslationToggle = !environment.production;
  }

  getCurrentYear(): string {
    return new Date().getFullYear().toString();
  }

  toggleTranslation(activate: boolean) {
    this.sessionFacade.userLogin$
      .pipe(
        take(1),
        switchMap(userLogin => {
          return this.authenticationService.getSettingsUser(userLogin.id);
        })
      )
      .subscribe((settings: ISettings) => {
        if (typeof activate === 'boolean') {
          const userLocale = { ...settings, locale: activate ? Language.Empty : settings.locale };
          this.sessionFacade.setUserSettings(userLocale);
        }
      });
  }
}
