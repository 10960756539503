import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import moment from 'moment';
import { LineChartOptions } from './model';

export enum TitlePosX {
  CENTER = 'CENTER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum DataAlignX {
  CENTER = 'CENTER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const defaultOptions: LineChartOptions = {
  margin: {
    top: 40,
    right: 20,
    left: 50,
    bottom: 40,
  },
  title: '',
  titlePosX: TitlePosX.CENTER,
  dataAlignX: DataAlignX.CENTER,
  xSelector: 'date',
  ySelector: 'value',
  xScalePadding: 0.3,
  timeFormat: 'hh:mm',
  dateFormat: 'DD.MM.YYYY',
};

@Component({
  selector: 'eon-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements OnInit, OnChanges {
  @Input()
  data: any;
  @Input()
  options: LineChartOptions;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.removeChart(this.host);
    this.initChart();
    this.renderChart();
  }

  host: HTMLElement;
  hostHeight: number;
  hostWidth: number;
  domainX: any[];
  domainY: any[];
  rangeX: any[];
  rangeY: any[];
  titlePosX: number;
  colWidthX: number;
  parseTime: any;
  parseTime2: any;
  plusTenPercent = 1.1;
  dataCpy;
  constructor(private elementRef: ElementRef) {
    this.parseTime = d3.utcParse('%Y-%m-%dT%H:%M:%S.%L%Z');
    this.parseTime2 = d3.utcParse('%Y-%m-%dT%H:%M:%S%Z');
  }

  ngOnInit() {
    this.parseTime = d3.utcParse('%Y-%m-%dT%H:%M:%S.%L%Z');
    this.parseTime2 = d3.utcParse('%Y-%m-%dT%H:%M:%S%Z');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.data &&
      changes.data.currentValue.lineChart &&
      changes.data.currentValue.lineChart.length > 0
    ) {
      this.data.lineChart.forEach(s => {
        s['currentValue'] = d3.max(s.history, d => d['value']);
        s['history'].forEach((item, idx) => {
          let date = this.parseTime(item.date);
          if (!date) {
            date = this.parseTime2(item.date);
          }
          item.date = date;
        });
      });
      this.removeChart(this.host);
      this.initChart();
      this.renderChart();
    }
  }

  initChart() {
    this.host = this.elementRef.nativeElement;
    this.setCurrentHostDimensions();
    this.getTitlePosX = this.getTitlePosX.bind(this);
    this.options = this.options || defaultOptions;
  }

  renderChart() {
    const margin = { top: 10, right: 120, bottom: 30, left: 80 };
    const width = this.hostWidth - margin.left - margin.right;
    const height = this.hostHeight - margin.top - margin.bottom;
    const dateFromTo = this.data.dateFromTo.map(this.parseTime);
    const maxValOfAllCharts = d3.max(
      this.data.lineChart
        .filter(data => data.show)
        .reduce((acc, cur) => {
          return [...acc, ...cur.history];
        }, []),
      d => d['value']
    );
    const roundedMax = Math.round(parseInt(maxValOfAllCharts) * this.plusTenPercent);

    const xScale = d3.scaleTime().rangeRound([0, width]).domain(dateFromTo);
    const yScale = d3
      .scaleLinear()
      .domain([0, roundedMax ? roundedMax : 1])
      .rangeRound([height, 0]);
    const line = d3
      .line()
      .x(d => xScale(d['date']))
      .y(d => yScale(d['value']));

    const chart = d3
      .select(this.host)
      .attr('style', 'position: relative')
      .append('svg')
      .attr('width', this.hostWidth)
      .attr('height', this.hostHeight)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    d3.select(this.host)
      .append('div')
      .attr('id', this.data.name)
      .attr(
        'style',
        'position:absolute;background-color:lightgrey;padding:5px; right: 40px; top: 35px; pointer-events: none;'
      );

    const tooltip = d3.select('#' + this.data.name);
    const tooltipLine = chart.append('line');

    const myTimeFormatter = date => moment(date).format(this.options.timeFormat);
    const myDateFormatter = date => moment(date).format(this.options.dateFormat);
    const xAxis = d3.axisBottom(xScale).tickFormat(myTimeFormatter);
    const yAxis = d3.axisLeft(yScale).ticks(roundedMax ? undefined : 1);
    chart.append('g').attr('class', 'yAxis').call(yAxis);
    chart
      .append('g')
      .attr('class', 'xAxis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);
    chart.append('text').html(this.options.title).attr('x', 200);

    let charts = this.data.lineChart;

    chart
      .selectAll()
      .data(charts.filter(data => data.show))
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', d => d['color'])
      .attr('stroke-width', 2)
      .datum(d => d['history'])
      .attr('d', line);

    chart
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('opacity', 0)
      .on('mousemove', drawTooltip)
      .on('mouseout', removeTooltip);

    function removeTooltip() {
      if (tooltip) tooltip.style('display', 'none');
      if (tooltipLine) tooltipLine.attr('stroke', 'none');
    }

    function drawTooltip(mouseEvent: MouseEvent) {
      const date = xScale.invert(d3.pointer(mouseEvent, mouseEvent.currentTarget)[0]);
      const bisectDate = d3.bisector(d => d['date']).right;

      charts.sort((a, b) => {
        return b.history.find(h => h.date == date) - a.history.find(h => h.date == date);
      });

      tooltipLine
        .attr('stroke', 'black')
        .attr('x1', xScale(date))
        .attr('x2', xScale(date))
        .attr('y1', 0)
        .attr('y2', height);

      tooltip
        .html(() => {
          return `${myDateFormatter(date)} ${myTimeFormatter(date)}`;
        })
        .style('display', 'block')
        // .style('width', '300px')
        .style('left', mouseEvent.pageX + 20)
        .style('top', mouseEvent.pageY - 20)
        .selectAll()
        .data(charts)
        .enter()
        .append('div')
        .style('color', d => d['color'])
        .html(d => {
          const moments = d['history'].map(i => moment(i.date));
          const minDate = moment.min(moments);
          const maxDate = moment.max(moments);

          if (moment(date).isBefore(minDate) || moment(date).isAfter(maxDate)) {
            return '';
          } else {
            const { value, unit } = d['history'][bisectDate(d['history'], date)];
            return `${d['name'].toUpperCase()}: ${value} ${unit}`;
          }
        });
    }
  }

  setCurrentHostDimensions() {
    const { top, right, left, bottom } = this.options.margin;
    this.hostHeight = this.getHostHeight() - top - bottom;
    this.hostWidth = this.getHostWidth() - left - right;
  }

  getHostHeight() {
    return this.getBoundingClientRect().height;
  }

  getHostWidth() {
    return this.getBoundingClientRect().width;
  }

  getBoundingClientRect() {
    return this.host.getBoundingClientRect();
  }

  getTitlePosX(titleSelection) {
    const { options, hostWidth } = this;
    const { margin } = options;
    const { clientWidth } = titleSelection._groups[0][0];
    switch (options.titlePosX) {
      case TitlePosX.CENTER:
        return (hostWidth + margin.left + margin.right) / 2 - clientWidth / 2;
      case TitlePosX.LEFT:
        return 0;
      case TitlePosX.RIGHT:
        return hostWidth - clientWidth;
      default:
        throw new Error(`Unknown TitlePosX: ${options.titlePosX}`);
    }
  }

  removeChart(host): void {
    d3.select(host).select('svg').remove();

    d3.select(host)
      .select('#' + this.data.name)
      .remove();
  }
}
