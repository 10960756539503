import { Injectable } from '@angular/core';
import { ISettings, IUserLogin } from '@auth/login';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { SessionActions } from './session.actions';
import { INotification } from './session.model';
import {
  datePattern,
  hasNewMessages,
  messagesShown,
  notification,
  selectedPartner,
  settingValueList,
  sidebarCollapsed,
  timePattern,
  unauthNavigatedPage,
  userLanguage,
  userLogin,
  userSettings,
  userTimezone,
  waitLogin,
} from './session.selector';

@Injectable({
  providedIn: 'root',
})
export class SessionFacade {
  currentUser$ = new BehaviorSubject<IUserLogin>(null);

  selectedPartnerId$ = this.ngrxStore.pipe(select(selectedPartner));
  userLogin$ = this.ngrxStore.pipe(select(userLogin));
  userSettings$ = this.ngrxStore.pipe(select(userSettings));
  userLanguage$ = this.ngrxStore.pipe(select(userLanguage));
  userTimezone$ = this.ngrxStore.pipe(select(userTimezone));
  datePattern$ = this.ngrxStore.pipe(select(datePattern));
  timePattern$ = this.ngrxStore.pipe(select(timePattern));
  hasNewMessages$ = this.ngrxStore.pipe(select(hasNewMessages));
  waitLogin$ = this.ngrxStore.pipe(select(waitLogin));
  settingValueList$ = this.ngrxStore.pipe(select(settingValueList));
  unauthNavigatedPage$ = this.ngrxStore.pipe(select(unauthNavigatedPage));
  notification$ = this.ngrxStore.pipe(select(notification));
  messagesShown$ = this.ngrxStore.pipe(select(messagesShown));
  sidebarCollapsed$ = this.ngrxStore.pipe(select(sidebarCollapsed));

  constructor(private ngrxStore: Store) {
    this.userLogin$.subscribe(userLogin => {
      this.currentUser$.next(userLogin);
    });
  }

  startLogin(username: string, password: string): void {
    this.ngrxStore.dispatch(SessionActions.startLogin({ username, password }));
  }

  loginSuccess(userLogin: IUserLogin): void {
    this.ngrxStore.dispatch(SessionActions.loginSuccess({ userLogin }));
  }

  logout(): void {
    this.ngrxStore.dispatch(SessionActions.logout());
  }

  saveUnauthNavigatedPageToStore(url: string): void {
    this.ngrxStore.dispatch(SessionActions.unauthNavigatedPage({ value: url }));
  }

  setUserSettings(settings: ISettings): void {
    this.ngrxStore.dispatch(SessionActions.setUserSettings({ settings }));
  }

  resetUserFilterOptions(): void {
    this.ngrxStore.dispatch(SessionActions.resetUserFilterOptions());
  }

  collapseSidebar(): void {
    this.ngrxStore.dispatch(SessionActions.collapseSidebar());
  }

  expandSidebar(): void {
    this.ngrxStore.dispatch(SessionActions.expandSidebar());
  }

  toggleMessages(): void {
    this.ngrxStore.dispatch(SessionActions.toggleMessages());
  }

  openMessages(): void {
    this.ngrxStore.dispatch(SessionActions.openMessages());
  }

  closeMessages(): void {
    this.ngrxStore.dispatch(SessionActions.closeMessages());
  }

  showNotification(notification: INotification): void {
    this.ngrxStore.dispatch(SessionActions.showTopNotification({ notification }));
  }

  closeNotification(): void {
    this.ngrxStore.dispatch(SessionActions.closeTopNotification());
  }

  hydrationCompleted(): void {
    this.ngrxStore.dispatch(SessionActions.hydrationCompleted());
  }
}
