<div class="file-component background-{{ backgroundColor }}">
  <div class="file-icon-wrapper">
    <eop-icon
      class="file-icon"
      [identifier]="isValidImageFormat(file) ? 'image-outlined' : 'document-download-outlined'"
      [vaylensFont]="true"
      [size]="IconSize.NORMAL"
    ></eop-icon>
  </div>
  <div class="file-name" matTooltip="{{ file.name }}" [matTooltipShowDelay]="300">
    {{ file.name }}
  </div>
  <div *ngIf="!hideRemoveIcon" class="remove-file-button" (click)="removeFile(file)">
    <eop-icon
      class="remove-icon"
      identifier="close-circle-filled"
      [vaylensFont]="true"
      [size]="IconSize.NORMAL"
    ></eop-icon>
  </div>
</div>
